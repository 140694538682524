<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <v-btn color="primary" @click="$refs.table.updateData()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title> </v-btn>
      <v-btn color="primary" @click="createNew()" title="Создать элемент"><btn-title icon="far fa-plus-square">Создать</btn-title></v-btn>
    </template>
    <portal to="v-main">
      <edit-dialog v-model="showEditDialog" :id="idEdit" :api="urlEdit" typeObject="agentObjects"></edit-dialog>
    </portal>
    <a-table-f-api ref="table" :api="url" :model="model" :useQuery="false" :defaults="defaults" @click="onClickRow($event)">
      <template v-slot:item.actions="{ row }">
        <div class="d-flex">
          <v-btn v-if="row.link_ref" elevation="0" x-small fab class="ma-0 ml-1" color="green" title="Перейти по ссылке" @click.stop="openLink(row)">
            <v-icon>fas fa-external-link-alt</v-icon>
          </v-btn>
        </div>
      </template>
    </a-table-f-api>
  </static-fullscreen-card>
</template>

<script>
import { getAccess, keyDetect, doubleClickDetect, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, keyDetect, doubleClickDetect, genModel],
  components: {
    editDialog: () => import("./dialogs/objectsLampDialog"),
  },
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      title: "",
      m: this.$store.getters["config/get"].models.lampObjects,
      url: "/mechti/agent_objects",
      urlEdit: "/mechti/agent_objects",
      defaults: {
        sort: { key: "id", order: "DESC" },
        filters: {},
        where: { status: [0, 1] },
        paramName: "agentObjects",
      },
    };
  },
  created() {
    this.$root.title = "Агентские объекты";
  },
  computed: {
    model() {
      let res = this.getModelList(this.m, "list", false);
      return res;
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    openLink(row) {
      window.open(row.link_ref, "_blank");
    },
    onClickRow(d) {
      if (d.field.name == "actions") return;
      this.$router.push({ name: "objectsAgent_view", params: { id: d.row.id } });
      // this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
